import React from 'react';
import stylesFooter from '@/shared/components/mainLayout/Footer.module.scss';
import { AnceIcon, CofeprisIcon } from '@/shared/icons';
import { useRouter } from 'next/router';

//copia de Footer de MainLayout y ajustado para no mostrar links
const CustomFooter = () => {
  const router = useRouter();
  return (
    <footer className={stylesFooter['footer']}>
      <div className={stylesFooter['container']}>
        <div className={stylesFooter['icon']}>
          <AnceIcon />
        </div>

        <p className={stylesFooter['copyright-text']}>
          Copyright © 2023 Rotoplas S.A. de C.V. Todos los derechos reservados.
        </p>

        <div className={stylesFooter['icon-cofepris']}>
          <CofeprisIcon />
        </div>
      </div>
    </footer>
  );
};

export default CustomFooter;
